<template>
  <div class="popup">
    <a-modal
        title="添加消息"
        :visible="visible"
        okText="确定"
        cancelText="取消"
        @cancel="visible = !visible"
        @ok="ok"
    >
      <div class="radio">
        <span>请选择消息类型：</span>
        <a-radio-group v-model="option.current" :options="option.list"/>
      </div>
      <div class="content" v-if="option.current === 'image'">
        <upload ref="imageFile" v-model="form.image"/>
      </div>
      <div class="content" v-if="option.current === 'link'">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" labelAlign="left">
          <a-form-item label="链接地址" required>
            <a-input v-model="form.link.url"/>
          </a-form-item>
          <a-form-item label="链接标题" required>
            <a-input v-model="form.link.title"/>
          </a-form-item>
          <a-form-item label="链接摘要">
            <a-input v-model="form.link.desc"/>
          </a-form-item>
          <a-form-item label="链接封面">
            <upload ref="imageFile" v-model="form.link.pic"/>
          </a-form-item>
        </a-form>
      </div>
      <div class="content" v-if="option.current === 'miniprogram'">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" labelAlign="left">
          <a-form-item label="小程序标题" required>
            <a-input v-model="form.miniprogram.title"/>
          </a-form-item>
          <a-form-item label="APPID" required>
            <a-input v-model="form.miniprogram.appid"/>
          </a-form-item>
          <a-form-item label="page路径" required>
            <a-input v-model="form.miniprogram.page"/>
          </a-form-item>
          <a-form-item label="图片封面" required>
            <upload ref="imageFile" v-model="form.miniprogram.pic"/>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import upload from "../upload";

export default {
  data() {
    return {
      visible: false,
      option: {
        list: [
          {label: '图片', value: 'image'},
          {label: '链接', value: 'link'},
          {label: '小程序', value: 'miniprogram'},
        ],
        current: 'image',
      },
      form: {
        image: {
          media_id: '',
          pic_url: ''
        },
        link: {
          title: '',
          pic: {},
          desc: '',
          url: ''
        },
        miniprogram: {
          title: '',
          pic: {},
          appid: '',
          page: ''
        }
      }
    }
  },
  methods: {
    ok() {
      if (this.option.current === 'image') {
        if (!this.form.image.fullPath) {
          this.$message.error('图片未上传');

          return false;
        }

        this.form.image.pic_url = this.form.image.path;

        this.$emit('change', {
          msgType: 'image',
          ...this.form.image
        });
      }

      if (this.option.current === 'link') {
        if (!this.form.link.url) {
          this.$message.error('地址未填写');

          return false;
        }

        const rep = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-\(\)]*[\w@?^=%&/~+#-\(\)])?$/;

        if (!this.form.link.url.match(rep)) {
          this.$message.error('链接地址不正确');

          return false;
        }

        if (!this.form.link.title) {
          this.$message.error('标题未填写');

          return false;
        }

        this.form.link.pic_url = this.form.link.pic.path;
        this.form.link.fullPath = this.form.link.pic.fullPath;

        this.$emit('change', {
          msgType: 'link',
          ...this.form.link
        });
      }

      if (this.option.current === 'miniprogram') {
        if (!this.form.miniprogram.title) {
          this.$message.error('标题未填写');

          return false;
        }

        if (!this.form.miniprogram.appid) {
          this.$message.error('APPID未填写');

          return false;
        }

        if (!this.form.miniprogram.page) {
          this.$message.error('page路径未填写');

          return false;
        }

        if (!this.form.miniprogram.pic.fullPath) {
          this.$message.error('图片未上传');

          return false;
        }

        const miniData = JSON.parse(JSON.stringify(this.form.miniprogram));
        this.form.miniprogram.pic_url = this.form.miniprogram.pic.path;
        this.form.miniprogram.fullPath = this.form.miniprogram.pic.fullPath;

        miniData.pic_media_id = miniData.pic.pic_media_id;
        this.$emit('change', {
          msgType: 'miniprogram',
          ...miniData
        });
      }

      this.visible = false;

      this.form = {
        image: {
          media_id: '',
          pic_url: ''
        },
        link: {
          title: '',
          pic: {},
          desc: '',
          url: ''
        },
        miniprogram: {
          title: '',
          pic: {},
          appid: '',
          page: ''
        }
      }
      this.$refs.imageFile.hide();
    },
    show() {
      this.visible = true
    }
  },
  components: {upload}
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-children {
  display: flex;
  align-items: center;
}

.content {
  margin-top: 23px;
}
</style>
