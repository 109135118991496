<template>
  <div class="upload">
    <a-upload
        name="file"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :headers="headers"
        :action="uploadApi"
        @change="handleChange"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script>
  import { getCookie } from '@/plugins/utils'
export default {
  data() {
    return {
      imageUrl: '',
      uploadApi: process.env.VUE_APP_API_BASE_URL + '/workbench/common/upload',
      loading: false
    }
  },
  computed: {
    headers () {
      const token = getCookie('workbench_token');
      return {
        Accept: `application/json`,
        Authorization: 'Bearer ' + token
      }
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      if (info.file.status === 'done') {
        this.getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });

        this.$emit('input', JSON.parse(JSON.stringify(info.file.response.data)));
      }
    },

    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    hide(){
      this.imageUrl = ''
    }
  }
}
</script>

<style lang="less" scoped>
img {
  width: 107px;
  height: 107px;
}
</style>
